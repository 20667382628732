<template>
  <v-theme-provider dark>
    <section id="product-description">
      <div class="video-div">
        <video autoplay muted loop contain>
          <source src="@/assets/background-vid.webm" type="video/mp4" />
        </video>
        <div class="overlay">
          <!-- <base-img
        :min-height="minHeight"
        :max-height="350"
        :gradient="gradient"
        src="@/assets/background-video.mp4"
        color="#45516b"
        flat
        max-width="100%"
        tile
      > -->
          <v-container class="fill-height px-4 py-4">
            <v-responsive
              class="d-flex align-center mx-auto"
              height="100%"
              max-width="700"
              width="100%"
            >
              <base-heading
                size="text-h3"
                title="HVS - PRODUCTS"
                weight="medium"
              />
              <base-body>
                Hedge Vision Systems' product offerings serves as a
                comprehensive suite of products for Alternate Asset Managers,
                with a strong emphasis on optimization of the deal funding
                process and data security. We aim to address the scattered and
                ad-hoc nature of deal analysis in most financial institutions,
                delivering a more efficient and effective approach to this
                critical process.
              </base-body>
            </v-responsive>
          </v-container>
          <!-- </base-img> -->
        </div>
      </div>
    </section>
  </v-theme-provider>
</template>

<script>
// Components
import { HexToRGBA, RGBAtoCSS } from "vuetify/lib/util/colorUtils";

export default {
  name: "ProductDescription",

  metaInfo() {
    return {
      changed: (meta) => (this.title = meta.titleChunk.toUpperCase()),
    };
  },

  provide: {
    heading: { align: "center" },
  },

  data: () => ({
    title: "",
  }),

  computed: {
    gradient() {
      const color = `${this.$vuetify.theme.themes.light.secondary}CC`;
      const overlay = RGBAtoCSS(HexToRGBA(color));

      return `to top, ${overlay}, ${overlay}`;
    },
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "60vh" : "30vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
<style>
.video-div {
  position: relative;
  width: 100%;
  max-height: 500px;
  overflow: hidden;
}

.video-div video {
  position: relative;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  min-width: 100%;
  height: auto;
}

.video-div .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.5);
}
</style>
